import { rolesAllowedToChooseAuthType } from '@/constants/shipit-administration.constants';
import Role from '@/enums/role';
import { StoreName } from '@/enums/stores.enum';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { defineStore } from 'pinia';
import { computed, ComputedRef, type Ref, ref } from 'vue';

const mainStore = defineStore(StoreName.MAIN, () => {
    const accessToken: Ref<string> = ref(' ');
    const username: Ref<string | null> = ref(null);
    const userRoles: Ref<Role[]> = ref([]);

    const setAccessToken = (token: string): void => {
        accessToken.value = token;
        const decodedUserToken: JwtPayload & { roles: Role[] } = jwtDecode(token);
        userRoles.value = decodedUserToken.roles;
    };

    const setUsername = (name: string): void => {
        username.value = name;
    };

    const isMaster: ComputedRef<boolean> = computed((): boolean => !!userRoles.value?.includes(Role.MASTER));
    const canChooseAuthType: ComputedRef<boolean> = computed(
        (): boolean => !!userRoles.value?.some((role: Role): boolean => rolesAllowedToChooseAuthType.includes(role))
    );

    return {
        accessToken,
        setAccessToken,
        setUsername,
        username,
        userRoles,
        isMaster,
        canChooseAuthType
    };
});

export default mainStore;
