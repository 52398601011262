import '@/assets/styles/main.scss';
import en from '@/assets/i18n/en.json';
import { mt1Environment, prodEnvironment, setFeatureFlagsConfig, stageEnvironment } from '@/constants/env';
import PermissionDirective from '@/directives/v-permission';
import EnvironmentHostName from '@/enums/environment-hostname';
import elementPlus from '@/plugins/element-plus';
import { EnvironmentConfig } from '@/types/environment';
import { VueQueryPlugin as vueQuery } from '@tanstack/vue-query';
import { getHostname, glsClearLocalStorageWhenAppVersionChange } from 'gls-app-configuration';
import { configureDynatrace } from 'gls-dynatrace';
import { TooltipDirective } from 'gls-table';
import { glsCreateI18nInstance } from 'gls-translate';
import { createPinia } from 'pinia';
import { createApp } from 'vue';

const APP_TITLE: string = 'Attribute Store';
const STAGE_DYNATRACE_RUM_APP_ID: string = '350bcc56cf90a77a';
const MT1_DYNATRACE_RUM_APP_ID: string = '410269a35d6a7b5b';
const PROD_DYNATRACE_RUM_APP_ID: string = 'a5ce7255f5889539';

const getAppTitle = (): string => {
    switch (getHostname()) {
        case EnvironmentHostName.PROD:
            return APP_TITLE;
        case EnvironmentHostName.LOCAL:
            return `${APP_TITLE} (LOCAL)`;
        case EnvironmentHostName.STAGE:
            return `${APP_TITLE} (STAGE)`;
        case EnvironmentHostName.MT1:
            return `${APP_TITLE} (MT1)`;
        default:
            return `${APP_TITLE} (DEV)`;
    }
};

const setUpDynatrace = (): void => {
    switch (getHostname()) {
        case EnvironmentHostName.STAGE:
            configureDynatrace(STAGE_DYNATRACE_RUM_APP_ID);
            break;
        case EnvironmentHostName.MT1:
            configureDynatrace(MT1_DYNATRACE_RUM_APP_ID);
            break;
        case EnvironmentHostName.PROD:
            configureDynatrace(PROD_DYNATRACE_RUM_APP_ID);
            break;
    }
};

const configureEnv = (): void => {
    const redirectURI: string = `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? ':' + window.location.port : ''
    }`;

    const envData = {
        VITE_AZURE_AD_AUTHORITY: 'https://login.microsoftonline.com/e6170c30-202d-4926-b525-b8b882873f3b',
        VITE_MSAL_REDIRECT_URI: redirectURI,
        VITE_MSAL_POSTLOGOUT_REDIRECT_URI: redirectURI
    };

    window.env = { ...envData, ...getEnvConfig() };
};

const getEnvConfig = (): EnvironmentConfig => {
    switch (getHostname()) {
        case EnvironmentHostName.PROD:
            return prodEnvironment;
        case EnvironmentHostName.MT1:
            return mt1Environment;
        default:
            return stageEnvironment;
    }
};

(async (): Promise<void> => {
    await setFeatureFlagsConfig();
    glsClearLocalStorageWhenAppVersionChange(APP_VERSION);
    configureEnv();
    setUpDynatrace();
    document.title = getAppTitle();

    const { default: App } = await import('@/App.vue');
    const { default: router } = await import('@/router/router');

    const app = createApp(App);

    app.directive('tooltip', TooltipDirective)
        .directive('permission', PermissionDirective)
        .use(router)
        .use(elementPlus)
        .use(vueQuery)
        .use(glsCreateI18nInstance({ en }))
        .use(createPinia())
        .mount('#app');
})();
