import Role from '@/enums/role';
import { AuthenticationType, ConsigneeIdPurpose, PrintLogo, ResponsibleRegion, ShipitRole } from '@/types/shipit-administration.type';

const rolesAllowedToChooseAuthType: Role[] = [Role.MASTER, Role.SHIPIT_COUNTRY_LEAD];

const shipitRoles: ShipitRole[] = [ShipitRole.FARM_USER, ShipitRole.GLS_ADMIN, ShipitRole.GPP_USER];
const authenticationType: AuthenticationType[] = [AuthenticationType.BASIC_AUTH, AuthenticationType.O_AUTH_2];
const printLogo: PrintLogo[] = [PrintLogo.PRINT_LOGO, PrintLogo.NONE];
const consigneeIdPurpose: ConsigneeIdPurpose[] = [ConsigneeIdPurpose.SHIPMENT_REFERENCE, ConsigneeIdPurpose.UNIT_REFERENCE];
const SHIPPER_DISPLAY_LENGTH: number = 15;
const FRENCH_CONTACT_IDS_PREFIX: string = '250';
const MASTER_ONLY_NAME: string = 'Core';

export {
    rolesAllowedToChooseAuthType,
    shipitRoles,
    authenticationType,
    printLogo,
    consigneeIdPurpose,
    SHIPPER_DISPLAY_LENGTH,
    FRENCH_CONTACT_IDS_PREFIX,
    MASTER_ONLY_NAME
};
