import { Shipper } from '@/types/api/shippers';

enum ShipitRole {
    FARM_USER = 'FarmUser',
    GLS_ADMIN = 'GLSAdmin',
    GPP_USER = 'GPPUser'
}

enum AuthenticationType {
    BASIC_AUTH = 'BasicAuth',
    O_AUTH_2 = 'OAuth2'
}

enum PrintLogo {
    PRINT_LOGO = 'PRINT_LOGO',
    NONE = 'NONE'
}

enum ConsigneeIdPurpose {
    SHIPMENT_REFERENCE = 'CONSIGNEE_ID_AS_SHIPMENT_REF',
    UNIT_REFERENCE = 'CONSIGNEE_ID_AS_UNIT_REF'
}

type ShipitUser = {
    name: string;
    oid: string;
    responsibleRegion: number | null;
    shippers: {
        shippers: Shipper[];
    };
};

type ResponsibleRegion = {
    id: number;
    name: string;
};

export { ShipitRole, AuthenticationType, PrintLogo, ConsigneeIdPurpose, type ShipitUser, type ResponsibleRegion };
