enum RouteName {
    LOGIN = 'Login',
    HOME = 'Home',
    GPP_USERS = 'GPPUsers',
    CREATE_GPP_USER = 'GPPUsersCreate',
    EDIT_GPP_USER = 'GPPUsersEdit',
    SHIPIT_ADMINISTRATION = 'ShipITAdministration',
    CREATE_SHIPIT_USER = 'ShipITAdministrationCreate',
    EDIT_SHIPIT_USER = 'ShipITAdministrationEdit',
    EDIT_SHIPPER_SETTINGS = 'ShipITAdministrationEditShipperSettings',
    CHANGE_SHIPPER_SETTINGS = 'ShipITAdministrationChangeShipperSettings'
}

export { RouteName };
