enum ActionName {
    CREATE_GPP_USER = 'CreateGPPUser',
    EDIT_GPP_USER = 'EditGPPUser',
    DELETE_GPP_USER = 'DeleteGPPUser',
    CREATE_SHIPIT_USER = 'CreateShipITUser',
    EDIT_SHIPIT_USER = 'EditShipITUser',
    DELETE_SHIPIT_USER = 'DeleteShipITUser',
    RESET_SHIPIT_USER_PARAMETER = 'ResetShipITUserParameter'
}

export default ActionName;
